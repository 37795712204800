import React from "react"

function Comment({ comment }) {
  return (
    <li>
      <h5>{comment.content}</h5> <p>{comment.name}</p>
    </li>
  )
}

export default Comment
