import React from "react"
import { graphql } from "gatsby"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { CommentSection } from "../components/Comments/index.js"
import Layout from '../components/Layout'
import Seo from '../components/Seo'

export default props => {
  const removeDiacritics = str => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  const { data } = props
  //const { previous } = props.pageContext
  const { markdownRemark, allMarkdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { edges } = allMarkdownRemark
  //const previousArticle = postIndex !== 0 && edges[postIndex - 1].node.fields.slug
  //const nextArticle = postIndex !== latestArticles.length && latestArticles[postIndex + 1].node.fields.slug
  
  return (

    <Layout className="blogPage blogPost" featuredImage={frontmatter.featuredImage && frontmatter.featuredImage.childImageSharp.fluid}>
      <Seo
        title={`${frontmatter.title && frontmatter.title} | Blog | Petriczech`}
        image={frontmatter.featuredImage && frontmatter.featuredImage.childImageSharp.fluid.src}
        description={frontmatter.excerpt && frontmatter.excerpt}
      />
      <div className="container">
        <div className="blogPost__head">
          
          <h1>{frontmatter.title}</h1>
          <Link to={`/blog/${frontmatter.category && removeDiacritics(frontmatter.category)}`}>
            {frontmatter.category}
          </Link><span>{frontmatter.date}</span>
        </div>

        <div className="blogPost__excerpt">
          <div className="right"></div>
          <p>{frontmatter.excerpt}</p>
        </div>
        <div
          className="blogPost__content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        
        <div className='blogPost__bottomMenu'>
          {/*
          {previousArticle &&
            <Link to={previousArticle}>
              <FormattedMessage
                defaultMessage='Previous article'
                id='blog.previous' />
            </Link>
          }
          */}
          <Link to={`/blog`} className='bottomMenu__center'>
              <FormattedMessage
                defaultMessage='All posts'
                id='blog.allPosts' />
          </Link>
          {/*
          {nextArticle &&
            <Link to={nextArticle}>
              <FormattedMessage
                defaultMessage='Next article'
                id='blog.next' />
            </Link>
          }
          */}
        </div>
        
        <div className='blogPost__latest'>
          <FormattedMessage
            tagName='h3'
            defaultMessage='Latest articles'
            id='blog.latest' />
            <ul>

               
            {edges.map(({node}) =>
              node.frontmatter.title &&
             node.frontmatter.title !== frontmatter.title &&
            <li key={node.id}><Link to={node.fields.slug}>
              {node.frontmatter.title}
            </Link></li>
             )}
          </ul>
        </div>
        <div className='blogPost__comments'>
          <CommentSection id={markdownRemark.fields.slug} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String! $locale: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        locale
        excerpt
        category
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
          frontmatter: {
            locale: {eq: $locale},
            pageKey: { 
                eq: "page_blogpost"
            }
          }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            locale
          }
        }
      }
    }
  }
`